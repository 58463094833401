/* 
*****************************************************
* TAILWIND CSS v3
*****************************************************
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
*****************************************************
* FONTS
*****************************************************
*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&family=Source+Sans+Pro:wght@300;400;600;900&display=swap');

$primary-font: 'Montserrat', sans-serif;
$secondary-font: 'Source Sans Pro', sans-serif;

html, body {
    font-family: $secondary-font;
}

h1, 
h2,
h3,
h4,
h5, 
h6,
nav {
    font-family: $primary-font;
}

/* 
*****************************************************
* ANIMATIONS
*****************************************************
*/

a {
    transition: all 0.4s ease;
}