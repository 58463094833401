$module: '.Clients';
#{$module} {
    position: relative;
    &:before {
        background: rgba(224,224,224,.75);
        content: '';
        height: 100%;
        left: 50%;
        position: absolute;
        top: 0;
        width: 1px;
        z-index: -1;
    }

    &__container {
        background: white;
        position: relative;
        z-index: 2;
        &:first-child,
        &:last-child {
            border-top-width: 1px;
            border-bottom-width: 1px;
            border-style: solid;
            border-color: rgba(224,224,224,.75);
            // margin-bottom: 6em;
            padding: 5px 0;             
        }
    }

    // &__row {
    //     border-top-width: 1px;
    //     border-bottom-width: 1px;
    //     border-style: solid;
    //     border-color: rgba(224,224,224,.75);
    // }

    img {
        height: 100%;
        margin: 0 auto;
    }

}