$module: '.Projects';
#{$module} {
    &:before {
        background: rgba(224,224,224,.75);
        content: '';
        height: 100%;
        left: 50%;
        position: absolute;
        top: 0;
        width: 1px;
        z-index: -1;
    }

    &__col {
        @media (min-width: 992px) {
            &:nth-child(odd) {
                #{$module} {
                    &__description {
                        padding-left: 5rem;
                    }
                }
            }
            &:nth-child(even) {
                #{$module} {
                    &__description {
                        padding-right: 5rem;
                        order: -1;
                        text-align: right;
                    }
                }
            }
        }
    }

    &__description {
        @media (min-width: 992px) {
            padding: 1.1rem;
        }
    }

    &__image {
        position: relative;
        @media (max-width: 991px) {
            min-height: 65vw;
        }
        &:after {
            content: '';
            background: black;
            display: block;
            height: 100%;
            left: 0;
            opacity: .1;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

}