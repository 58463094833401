$module: '.Content';
#{$module} {
    position: relative;

    &:before {
        background: rgba(224,224,224,.75);
        content: '';
        height: 100%;
        left: 50%;
        position: absolute;
        top: 0;
        width: 1px;
        z-index: -1;
    }

    &__row {
        border-top-width: 1px;
        border-bottom-width: 1px;
        border-style: solid;
        border-color: rgba(224,224,224,.75);
        margin-bottom: 6em;
        padding: 10px 0;
        position: relative;
        background: white;
        z-index: 2;
    }

    &__col {
        border-top-width: 1px;
        border-bottom-width: 1px;
        border-style: solid;
        border-color: rgba(224,224,224,.75);
    }

    &__title {
        border-bottom-width: 1px;
        border-color: rgba(224,224,224,.75);
    }

}