$module: '.Footer';
#{$module} {
    background-attachment: scroll;
    background-image: url('./../../assets/images/bottom-3200.svg');
    background-position: top center;
    background-repeat: repeat-x;
    background-size: 3200px 460px;
    color: rgba(255,255,255,.8);
    margin-top: -260px;
    padding: calc(460px + 6em) 0 10em 0;
    position: relative;
    text-align: center;

    &:before {
        background: rgba(224,224,224,.75);
        content: '';
        height: calc(460px + 6em);
        left: 50%;
        position: absolute;
        top: 0;
        width: 1px;
        z-index: 0;
    }

    &__row {
        border-color: rgba(224,224,224,.75);
        border-style: solid;
        border-top-width: 1px;
        position: relative;
    }

    &__col {
        border-color: rgba(224,224,224,.75);
        border-style: solid;
        border-top-width: 1px;
        margin-top: 10px;
    }

    &__social {
        a {
            // border: solid 1px white;
        }
    }

    a {
        &.dotted {
            border-bottom: dotted 1px;
        }
    }

}