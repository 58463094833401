$module: '.Hero';
#{$module} {
    min-height: 750px;
    background-attachment:		scroll,							                scroll,					                    fixed;
    background-image:			url('./../../assets/images/top-3200.svg'),	    url('./../../assets/images/overlay.png'),	url('./../../assets/images/header.jpg');
    background-position:		bottom center,					                top left,				                    center center;
    background-repeat:			repeat-x,						                repeat,					                    no-repeat;
    background-size:			3200px 460px,					                auto,					                    cover;

    &__icon {
        height: 100px;
        width: 100px;
    }

    &__row {
        position: relative;
        z-index: 2;
    }
    
    &:before,
    &:after {
        background: rgba(224,224,224,.75);
        content: '';
        left: 50%;
        position: absolute;
        width: 1px;
        z-index: 0;
    }

    &:before {
        height: calc(100% - 50px);
        opacity: .15;
        top: 0;
    }

}